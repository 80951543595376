const filterToggles = document.querySelectorAll('.filtersToggle') as NodeListOf<HTMLButtonElement>;
const [filterToggleBtn, filterCloseBtn] = filterToggles;
const filtersWrap = document.querySelector('.filters-wrap') as HTMLDivElement;
const isMobileView = window.matchMedia("(max-width: 768px)"); // should match "$md" variable in _variables.scss
let filtersWrapFocusableElements: NodeListOf<HTMLAnchorElement | HTMLButtonElement>;

type setFilterFocusabilityParams = {
    tabindex: string,
    ariaHidden: string,
    ariaExpanded: string
}

if (filtersWrap) {
    filtersWrapFocusableElements = filtersWrap.querySelectorAll<HTMLAnchorElement | HTMLButtonElement>('a, button');

    const toggleFilters : () => void = () => {
        if (filtersWrap.classList.contains('__active')) {
            closeFilters();
        } else {
            openFilters();
        }
    }

    const openFilters : () => void = () => {
        setFilterFocusability({tabindex: '0', ariaHidden: 'false', ariaExpanded: 'true'});
        filtersWrap.classList.add('__active');
        document.body.classList.add('__noscroll');
        filtersWrap.focus();
        filtersWrap.addEventListener('keydown', filtersWrapKeyboardControls);
    }

    const closeFilters : () => void = () => {
        setFilterFocusability({tabindex: '-1', ariaHidden: 'true', ariaExpanded: 'false'});
        filtersWrap.classList.remove('__active');
        document.body.classList.remove('__noscroll');
        filterToggleBtn.focus();
    }

    const setFilterFocusability : ({tabindex, ariaHidden, ariaExpanded} : setFilterFocusabilityParams) => void = ({tabindex, ariaHidden, ariaExpanded} : setFilterFocusabilityParams) => {
        filtersWrap.setAttribute('tabindex', tabindex);
        filtersWrapFocusableElements.forEach(element => {
            element.setAttribute('tabindex', tabindex);
        });
        filtersWrap.setAttribute('aria-hidden', ariaHidden);
        filterToggleBtn.setAttribute('aria-expanded', ariaExpanded);
    }

    const filtersWrapKeyboardControls : (e: KeyboardEvent) => void = (e: KeyboardEvent) => {
        const firstFocusableElement = filtersWrapFocusableElements[0];
        const lastFocusableElement = filtersWrapFocusableElements[filtersWrapFocusableElements.length - 1];

        if (e.key == 'Escape') {
            closeFilters();
        }

        if (e.key == 'Tab') {
            if (e.shiftKey) {
                if (document.activeElement == firstFocusableElement) {
                    e.preventDefault();
                    lastFocusableElement.focus();
                }
            } else {
                if (document.activeElement == lastFocusableElement) {
                    e.preventDefault();
                    firstFocusableElement.focus();
                }
            }
        }
    }

    if(isMobileView.matches) {
        setFilterFocusability({tabindex: '-1', ariaHidden: 'true', ariaExpanded: 'false'});
        
        filterToggles.forEach(toggle => {
            toggle.addEventListener('click', toggleFilters);
        });
    }
}
